<template>

  <section class="forms">
    <div class="page-header">
      <h3 class="page-title">
        Add New Admin
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Admins</a></li>
          <li class="breadcrumb-item active" aria-current="page">New</li>
        </ol>
      </nav>
    </div>
    <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="form">
    <form @submit.prevent="handleSubmit(onSubmit)" class="forms-sample">
    <div class="row">
      <div class="col-md-6 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Name</h4>
            <p class="card-description">
              First name & Last name
            </p>
             <ValidationProvider name="first_name" rules="required">
              <b-form-group slot-scope="{ errors }" label="First Name" label-for="first_name">
                <b-form-input type="text" id="first_name" v-model="first_name" placeholder="First Name"></b-form-input>
                <p>{{ errors[0] }}</p>
              </b-form-group>
              </ValidationProvider>
              <ValidationProvider name="last_name" rules="required">
              <b-form-group slot-scope="{ errors }" label="Last Name" label-for="last_name">
                <b-form-input type="text" id="last_name" v-model="last_name" placeholder="Last Name"></b-form-input>
                <p>{{ errors[0] }}</p>
              </b-form-group>
              </ValidationProvider>
          </div>
        </div>
      </div>

      <div class="col-md-6 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Authentication</h4>
            <p class="card-description">
              Email & Password
            </p>
            <ValidationProvider name="email" rules="required|email">
              <b-form-group slot-scope="{ errors }" label="Email address"  label-for="email">
                <b-form-input type="email" id="email" v-model="email" placeholder="Email"></b-form-input>
                <p>{{ errors[0] }}</p>
              </b-form-group>
              </ValidationProvider>
              <ValidationProvider name="password" rules="required|min:6">
              <b-form-group label="Password" slot-scope="{ errors }" label-for="password">
                <b-form-input type="password" v-model="password" id="password" placeholder="Password"></b-form-input>
                <p>{{ errors[0] }}</p>
              </b-form-group>
              </ValidationProvider>
          </div>
        </div>
      </div>
      <div class="col-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <b-form-group class="float-right">
              <b-button type="submit" variant="success" :disabled="invalid" class="mr-2">{{ create_text }}</b-button>
              <b-button type="button" @click="resetForm" variant="light">Reset</b-button>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
    </form>
    </ValidationObserver>
    <vue-snotify></vue-snotify>
  </section>

</template>

<script lang="js">
/* eslint-disable no-debugger, no-console */

import { ValidationObserver } from 'vee-validate'

export default {
  name: 'adminAddNew',
  data () {
    return {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      create_text: "Create Admin"
    }
  },
  components: {
    ValidationObserver
  },
  methods: {
    onSubmit() {
      this.create_text = "Creating..."
      let payload = {
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        password: this.password
      }
      this.$store.dispatch('admin/createAdmin', payload)
      .then(() => {
        this.create_text = "Create Admin"
        this.resetForm()
        this.$snotify.success("Account created successfully!", {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        })
      })
      .catch(() => {
        this.create_text = "Create Admin"

        this.$snotify.warning("Something went wrong!", {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        })
      })
    },
    resetForm() {
      this.first_name = this.last_name = this.email = this.password = ""

      this.$nextTick(() => {
        this.$refs.form.reset();
      })
    }
  }
}
</script>
